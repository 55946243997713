import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import differenceBy from 'lodash/differenceBy';
import { getCurrentTimestamp } from '../../helpers/timeHelper';
class DriverStatusBoxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDrivers: [],
      driverWhoseStatusChanged: [],
      storeTimes: {},
      driverNameNotificationMapping: {},
      arrivedOrClocked: {},
    };
  }
  componentDidMount() {
    if (this.props.activeDrivers) {
      let activeDrivers = [];
      let driversWithStatus0 = [];
      let driversWithStatus1 = [];
      let driversWithStatus2 = [];
      this.props.activeDrivers.forEach((driver, index) => {
        driver.index = index;
        if (driver.status === 0) {
          driversWithStatus0.push(driver);
        }
        if (driver.status === 1) {
          driversWithStatus1.push(driver);
        }
        if (driver.status === 2) {
          driversWithStatus2.push(driver);
        }
      });
      activeDrivers = [
        ...driversWithStatus0,
        ...driversWithStatus2,
        ...driversWithStatus1,
      ];
      this.setState(
        {
          activeDrivers,
        },
        () => {
          let activeDriversList = cloneDeep(this.state.activeDrivers);
          activeDriversList.forEach(k => {
            k.class = `${k.class} show`;
            k.idleTime = this.getIdleTime(k.lastUpdatedTimestamp);
            return k;
          });
          // let driverObj = {};
          // activeDriversList.forEach(driver => {
          //   driverObj[driver._id] = '';
          // });
          // this.setState({ arrivedOrClocked: driverObj });
          setTimeout(() => {
            this.setState({ activeDrivers: activeDriversList });
          }, 100);
          setInterval(this.updateIdleTime, 60000);
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeDrivers.length !== this.props.activeDrivers.length &&
      this.props.activeDrivers.length === 0
    ) {
      this.setState({ activeDrivers: [] });
    }

    if (
      prevProps.activeDrivers.length === this.props.activeDrivers.length &&
      this.props.activeDrivers.length !== 0
    ) {
      let activeDrivers = cloneDeep(this.props.activeDrivers);
      let previousDrivers = cloneDeep(prevProps.activeDrivers);
      activeDrivers.forEach((driver, index) => {
        previousDrivers.forEach((prevDriver, prevIndex) => {
          if (
            activeDrivers[index].status === 2 &&
            previousDrivers[index].status === 2
          ) {
            if (
              activeDrivers[index].currentLocation.lat !==
                previousDrivers[index].currentLocation.lat &&
              activeDrivers[index].currentLocation.long !==
                previousDrivers[index].currentLocation.long
            )
              if (
                driver.activeShift &&
                driver.activeShift.arrivingToStoreTime
              ) {
                let newStoreTimes = cloneDeep(this.state.storeTimes);
                let driverNotificationMapping = cloneDeep(
                  this.state.driverNameNotificationMapping
                );
                newStoreTimes[driver._id] = `${
                  driver.activeShift.arrivingToStoreTime === '0 mins'
                    ? `Arriving Now`
                    : `Arriving in ${driver.activeShift.arrivingToStoreTime}`
                }`;

                driverNotificationMapping[driver.userName] = `Arriving in ${
                  driver.activeShift.arrivingToStoreTime
                }`;
                // console.log(newStoreTimes);
                // console.log(driverNotificationMapping);
                this.setState({
                  storeTimes: newStoreTimes,
                  driverNameNotificationMapping: driverNotificationMapping,
                });
              }
          }
        });
        // if (driver.status === 2) {
        //   console.log('driver', driver);

        // }
      });
    }

    if (
      prevProps.activeDrivers.length !== this.props.activeDrivers.length &&
      this.props.activeDrivers.length !== 0
    ) {
      if (prevProps.activeDrivers.length < this.props.activeDrivers.length) {
        //New Driver added
        let activeDrivers = cloneDeep(this.props.activeDrivers);
        let previousDrivers = cloneDeep(prevProps.activeDrivers);

        let newlyAddedDriver = differenceBy(
          activeDrivers,
          previousDrivers,
          '_id'
        );
        let first = cloneDeep(newlyAddedDriver);
        first[0].index = activeDrivers.length - 1;
        first[0].class = `hide-notification ${first[0].class}`;

        let arrivedOrClockedObj = { ...this.state.arrivedOrClocked };
        arrivedOrClockedObj[first[0]._id] = 'Clocked In';
        //Need to delete driver which is introduced
        let indexToDelete;
        activeDrivers.forEach((driver, index) => {
          if (driver._id === newlyAddedDriver[0]._id) {
            indexToDelete = index;
          }
        });
        activeDrivers.splice(indexToDelete, 1);
        activeDrivers.forEach((activeDriver, index) => {
          activeDriver.index = index;
          activeDriver.idleTime = this.getIdleTime(
            activeDriver.lastUpdatedTimestamp
          );
          activeDriver.class = `${activeDriver.class} show`;
        });
        // Added
        let sortedActiveDriver = sortBy(activeDrivers, ['status']);
        this.setState(
          {
            activeDrivers: sortedActiveDriver,
            arrivedOrClocked: arrivedOrClockedObj,
          },
          () => {
            let newNotifications = cloneDeep(this.state.activeDrivers);
            this.setState({ activeDrivers: newNotifications }, () => {
              setTimeout(() => {
                // Check existing status boxes - If status boxes have id 1 or 2 then [First Notification] should be inserted above it
                // else insert the notification at the last (If all existing drivers have status 0 then too the notification will come at bottom)
                let ultimateNotifications = cloneDeep(this.state.activeDrivers);
                let inner = [...ultimateNotifications, ...first];
                let flag = 'last';
                let driversToBeArranged = cloneDeep(this.state.activeDrivers);
                for (let i = 0; i < driversToBeArranged.length; i++) {
                  const current = driversToBeArranged[i];
                  //if there are any drivers with status 1 or 2
                  if (current.status === 1 || current.status === 2) {
                    inner = [...first, ...ultimateNotifications];
                    flag = 'first';
                    break;
                  } else if (current.status === 0) {
                    let i = 0;
                    while (
                      i <= driversToBeArranged.length - 1 &&
                      driversToBeArranged[i].status === 0
                    ) {
                      i++;
                    }
                    inner.splice(-1, 1);
                    inner.splice(i, 0, first[0]);
                    break;
                  }
                }
                this.setState({ activeDrivers: inner }, () => {
                  //debugger;
                  setTimeout(() => {
                    let animateNotification = cloneDeep(
                      this.state.activeDrivers
                    );
                    let classString;
                    if (flag === 'last') {
                      let indexToAnimate;
                      animateNotification.forEach((driver, index) => {
                        if (driver._id === first[0]._id) {
                          indexToAnimate = index;
                        }
                      });
                      if (
                        animateNotification &&
                        animateNotification[indexToAnimate] !== undefined
                      ) {
                        classString = animateNotification[indexToAnimate].class;
                        classString = classString.replace(
                          'hide-notification',
                          ''
                        );
                        animateNotification[indexToAnimate].class = classString;
                      }
                    } else if (flag === 'first') {
                      if (
                        animateNotification &&
                        animateNotification[0] !== undefined
                      ) {
                        classString = animateNotification[0].class;
                        classString = classString.replace(
                          'hide-notification',
                          ''
                        );
                        //classString = classString + ' show';
                        animateNotification[0].class = classString;
                      }
                    }
                    //console.log(animateNotification);
                    this.setState(
                      {
                        activeDrivers: animateNotification,
                      },
                      () => {
                        setTimeout(() => {
                          let animateNotificationNew = cloneDeep(
                            this.state.activeDrivers
                          );
                          let classStringNew;
                          let indexToAnimateNew;
                          animateNotificationNew.forEach((driver, index) => {
                            if (driver._id === first[0]._id) {
                              indexToAnimateNew = index;
                            }
                          });
                          if (flag === 'last') {
                            if (
                              animateNotificationNew &&
                              animateNotificationNew[indexToAnimateNew] !==
                                undefined
                            ) {
                              classStringNew =
                                animateNotificationNew[indexToAnimateNew].class;
                              classStringNew = classStringNew + ' show';
                              animateNotificationNew[
                                indexToAnimateNew
                              ].class = classStringNew;
                            }
                          } else if (flag === 'first') {
                            if (
                              animateNotificationNew &&
                              animateNotificationNew[0] !== undefined
                            ) {
                              classStringNew = animateNotificationNew[0].class;
                              classStringNew = classStringNew + ' show';
                              animateNotificationNew[0].class = classStringNew;
                            }
                          }
                          this.setState({
                            activeDrivers: animateNotificationNew,
                          });
                        }, 500);
                      }
                    );
                  }, 600);
                });
              }, 600);
            });
          }
        );
      }

      if (prevProps.activeDrivers.length > this.props.activeDrivers.length) {
        // Find the driver which ended the shift
        let previousDrivers = cloneDeep(prevProps.activeDrivers);
        let currentDrivers = cloneDeep(this.props.activeDrivers);
        let driversInState = cloneDeep(this.state.activeDrivers);

        let shiftEndedDriver = previousDrivers.filter(driver => {
          return !currentDrivers.some(driver2 => {
            return driver._id === driver2._id;
          });
        });

        // Iterate over drivers in state and remove show from the id of driver which left
        driversInState.forEach(driver => {
          if (driver._id === shiftEndedDriver[0]._id) {
            let classString = driver.class;
            classString = classString.replace('show', '');
            driver.class = classString;
          }
        });

        this.setState({
          activeDrivers: driversInState,
        });
      }
    }

    //Status Changed for Driver, No Driver added or removed
    if (
      prevProps.activeDrivers.length === this.props.activeDrivers.length &&
      this.props.activeDrivers.length !== 0
    ) {
      // console.log('Driver status changed');
      // console.log('prevProps', prevProps.activeDrivers);
      // console.log('currentProps', this.props.activeDrivers);

      let oldStatusDriver = cloneDeep(prevProps.activeDrivers);
      let newStatusDriver = cloneDeep(this.props.activeDrivers);
      let currentState = cloneDeep(this.state.activeDrivers);
      let driverWhoseStatusChanged = [];

      // console.log('oldStatusDriver', prevProps.activeDrivers);
      // console.log('newStatusDriver', this.props.activeDrivers);

      oldStatusDriver.forEach((oldDriver, indexOld) => {
        newStatusDriver.forEach((newDriver, indexNew) => {
          if (indexOld === indexNew) {
            if (oldDriver.status !== newDriver.status) {
              // this.setState({
              //   driverWhoseStatusChanged: [],
              // });
              driverWhoseStatusChanged.push({
                index: indexNew,
                oldDriver: oldDriver,
                newDriver: newDriver,
              });
              // this.setState({
              //   driverWhoseStatusChanged,
              // });
            }
          }
        });
      });

      if (
        driverWhoseStatusChanged.length === 1
        // &&
        // this.state.driverWhoseStatusChanged.length === 1
      ) {
        //Fade out the notification whose status is changed.
        //console.log('This should come once', driverWhoseStatusChanged);
        currentState.forEach((driver, index) => {
          //driver.index = index;
          if (driver._id === driverWhoseStatusChanged[0].oldDriver._id) {
            // remove show from it's class
            //console.log('Here remove show from class', driver);
            //driver.index = index;
            let classString = driver.class;
            classString = classString.replace('show', '');
            driver.class = classString;
          }
        });
        this.setState(
          {
            activeDrivers: currentState,
          },
          () => {
            setTimeout(() => {
              let newActiveDrivers = cloneDeep(this.state.activeDrivers);
              //let indexOfDriverToBeDeleted;
              newActiveDrivers.forEach((driver, index, array) => {
                if (driver._id === driverWhoseStatusChanged[0].newDriver._id) {
                  let newDriverObj = cloneDeep(
                    driverWhoseStatusChanged[0].newDriver
                  );
                  array[index] = newDriverObj;
                }
              });
              this.setState(
                {
                  activeDrivers: newActiveDrivers,
                },
                () => {
                  this.setState(
                    {
                      activeDrivers: newActiveDrivers,
                    },
                    () => {
                      setTimeout(() => {
                        let internalNewDrivers = cloneDeep(
                          this.state.activeDrivers
                        );
                        // Driver status Changed to On Delivery
                        if (
                          driverWhoseStatusChanged[0].newDriver.status === 1
                        ) {
                          let driverDelivery = cloneDeep(internalNewDrivers);
                          let newDriver;
                          let indexOfDriverWhoseStatusChanged;
                          driverDelivery.forEach((driver, index) => {
                            if (
                              driver._id ===
                              driverWhoseStatusChanged[0].newDriver._id
                            ) {
                              indexOfDriverWhoseStatusChanged = index;
                            }
                          });
                          let splicedDriver = driverDelivery.splice(
                            indexOfDriverWhoseStatusChanged,
                            1
                          );
                          splicedDriver[0].class = `hide-notification ${
                            splicedDriver[0].class
                          }`;
                          newDriver = [...driverDelivery, ...splicedDriver];

                          this.setState(
                            {
                              activeDrivers: newDriver,
                            },
                            () => {
                              //debugger;
                              // Need to add hide-notification first then remove it here and then add show in next callback
                              setTimeout(() => {
                                let animatedDriver = cloneDeep(
                                  this.state.activeDrivers
                                );
                                let classStringNew;
                                if (
                                  animatedDriver &&
                                  animatedDriver[animatedDriver.length - 1] !==
                                    undefined
                                ) {
                                  classStringNew =
                                    animatedDriver[animatedDriver.length - 1]
                                      .class;
                                  classStringNew = classStringNew.replace(
                                    'hide-notification',
                                    ''
                                  );
                                  animatedDriver[
                                    animatedDriver.length - 1
                                  ].class = classStringNew;
                                }
                                this.setState(
                                  {
                                    activeDrivers: animatedDriver,
                                  },
                                  () => {
                                    let animateNotificationNew = cloneDeep(
                                      this.state.activeDrivers
                                    );
                                    setTimeout(() => {
                                      if (
                                        animateNotificationNew &&
                                        animateNotificationNew[
                                          animateNotificationNew.length - 1
                                        ] !== undefined
                                      ) {
                                        classStringNew =
                                          animateNotificationNew[
                                            animateNotificationNew.length - 1
                                          ].class;
                                        classStringNew =
                                          classStringNew + ' show';
                                        animateNotificationNew[
                                          animateNotificationNew.length - 1
                                        ].class = classStringNew;
                                      }
                                      this.setState({
                                        activeDrivers: animateNotificationNew,
                                      });
                                    }, 50);
                                  }
                                );
                              }, 50);
                            }
                          );
                        }

                        // Driver status Changed to In Store
                        if (
                          driverWhoseStatusChanged[0].newDriver.status === 0
                        ) {
                          //Here the Changed Driver should come at the top
                          let driverInStore = cloneDeep(internalNewDrivers);
                          let newDriver;
                          let indexOfdriverToBePushedAtTop;
                          driverInStore.forEach((driver, index) => {
                            if (
                              driver._id ===
                              driverWhoseStatusChanged[0].newDriver._id
                            ) {
                              indexOfdriverToBePushedAtTop = index;
                            }
                          });
                          let splicedDriver = driverInStore.splice(
                            indexOfdriverToBePushedAtTop,
                            1
                          );
                          let driverObj = { ...this.state.arrivedOrClocked };
                          driverObj[splicedDriver[0]._id] = 'Just Arrived';

                          splicedDriver[0].class = `hide-notification ${
                            splicedDriver[0].class
                          }`;
                          if (
                            driverInStore[0] &&
                            driverInStore[0].status === 0
                          ) {
                            let i = 0;
                            while (
                              i <= driverInStore.length - 1 &&
                              driverInStore[i].status === 0
                            ) {
                              i++;
                            }
                            driverInStore.splice(i, 0, splicedDriver[0]);
                            newDriver = [...driverInStore];
                          } else {
                            newDriver = [...splicedDriver, ...driverInStore];
                          }
                          this.setState(
                            {
                              activeDrivers: newDriver,
                              arrivedOrClocked: driverObj,
                            },
                            () => {
                              setTimeout(() => {
                                let animatedDriver = cloneDeep(
                                  this.state.activeDrivers
                                );
                                let indexToAnimate;
                                animatedDriver.forEach((driver, index) => {
                                  if (
                                    driver._id ===
                                    driverWhoseStatusChanged[0].newDriver._id
                                  ) {
                                    indexToAnimate = index;
                                  }
                                });
                                let classStringNew;
                                if (
                                  animatedDriver &&
                                  animatedDriver[indexToAnimate] !== undefined
                                ) {
                                  classStringNew =
                                    animatedDriver[indexToAnimate].class;
                                  classStringNew = classStringNew.replace(
                                    'hide-notification',
                                    ''
                                  );
                                  animatedDriver[
                                    indexToAnimate
                                  ].class = classStringNew;
                                }
                                this.setState(
                                  {
                                    activeDrivers: animatedDriver,
                                  },
                                  () => {
                                    let animateNotificationNew = cloneDeep(
                                      this.state.activeDrivers
                                    );
                                    let indexToAnimateNew;
                                    animateNotificationNew.forEach(
                                      (driver, index) => {
                                        if (
                                          driver._id ===
                                          driverWhoseStatusChanged[0].newDriver
                                            ._id
                                        ) {
                                          indexToAnimateNew = index;
                                        }
                                      }
                                    );
                                    setTimeout(() => {
                                      if (
                                        animateNotificationNew &&
                                        animateNotificationNew[
                                          indexToAnimateNew
                                        ] !== undefined
                                      ) {
                                        classStringNew =
                                          animateNotificationNew[
                                            indexToAnimateNew
                                          ].class;
                                        classStringNew =
                                          classStringNew + ' show';
                                        animateNotificationNew[
                                          indexToAnimateNew
                                        ].class = classStringNew;
                                      }
                                      this.setState({
                                        activeDrivers: animateNotificationNew,
                                      });
                                    }, 400);
                                  }
                                );
                              }, 400);
                            }
                          );
                        }

                        //console.log('internalNewDrivers', [...internalNewDrivers]);
                        // When Status Changed to Returning to store
                        if (
                          driverWhoseStatusChanged[0].newDriver.status !== 1 &&
                          driverWhoseStatusChanged[0].newDriver.status !== 0
                        ) {
                          // Need to fetch arrivingToStoreTime and set it to storeTimes in state,

                          let driverReturningToStore = cloneDeep(
                            internalNewDrivers
                          );
                          let indexOfdriverToBePushedAfterInstore;
                          driverReturningToStore.forEach((driver, index) => {
                            if (
                              driver._id ===
                              driverWhoseStatusChanged[0].newDriver._id
                            ) {
                              indexOfdriverToBePushedAfterInstore = index;
                            }
                          });
                          let splicedDriver = driverReturningToStore.splice(
                            indexOfdriverToBePushedAfterInstore,
                            1
                          );
                          splicedDriver[0].class = `hide-notification ${
                            splicedDriver[0].class
                          }`;

                          let newStoreTimes = cloneDeep(this.state.storeTimes);
                          let driverNotificationMapping = cloneDeep(
                            this.state.driverNameNotificationMapping
                          );
                          newStoreTimes[splicedDriver[0]._id] = ` Arriving in ${
                            splicedDriver[0].activeShift.arrivingToStoreTime ===
                            undefined
                              ? '0 mins'
                              : `${
                                  splicedDriver[0].activeShift
                                    .arrivingToStoreTime
                                }`
                          }`;
                          driverNotificationMapping[
                            splicedDriver[0].userName
                          ] = `Arriving in ${
                            splicedDriver[0].activeShift.arrivingToStoreTime
                          }`;
                          // console.log('spliced Driver', splicedDriver[0]);
                          // console.log('new store times', newStoreTimes);
                          // console.log(
                          //   'driverNotificationMapping',
                          //   driverNotificationMapping
                          // );
                          // Need to Iterate over driverReturningToStore and check
                          // until we find driver with status 0 and then insert after it or else
                          // push it on top*
                          let newDriver;
                          if (
                            driverReturningToStore &&
                            driverReturningToStore[0] &&
                            driverReturningToStore[0].status === 0
                          ) {
                            let i = 0;
                            while (
                              i <= driverReturningToStore.length - 1 &&
                              (driverReturningToStore[i].status === 0 ||
                                driverReturningToStore[i].status === 2)
                            ) {
                              i++;
                            }
                            driverReturningToStore.splice(
                              i,
                              0,
                              splicedDriver[0]
                            );
                            newDriver = [...driverReturningToStore];
                          } else {
                            newDriver = [
                              ...splicedDriver,
                              ...driverReturningToStore,
                            ];
                          }
                          this.setState(
                            {
                              activeDrivers: newDriver,
                              storeTimes: newStoreTimes,
                              driverNameNotificationMapping: driverNotificationMapping,
                            },
                            () => {
                              this.props.notificationMappingHandler(
                                this.state.driverNameNotificationMapping
                              );
                              setTimeout(() => {
                                let animatedDriver = cloneDeep(
                                  this.state.activeDrivers
                                );
                                let indexToAnimate;
                                animatedDriver.forEach((driver, index) => {
                                  if (
                                    driver._id ===
                                    driverWhoseStatusChanged[0].newDriver._id
                                  ) {
                                    indexToAnimate = index;
                                  }
                                });
                                let classStringNew;
                                if (
                                  animatedDriver &&
                                  animatedDriver[indexToAnimate] !== undefined
                                ) {
                                  classStringNew =
                                    animatedDriver[indexToAnimate].class;
                                  classStringNew = classStringNew.replace(
                                    'hide-notification',
                                    ''
                                  );
                                  animatedDriver[
                                    indexToAnimate
                                  ].class = classStringNew;
                                }
                                this.setState(
                                  {
                                    activeDrivers: animatedDriver,
                                  },
                                  () => {
                                    let animateNotificationNew = cloneDeep(
                                      this.state.activeDrivers
                                    );
                                    setTimeout(() => {
                                      if (
                                        animateNotificationNew &&
                                        animateNotificationNew[
                                          indexToAnimate
                                        ] !== undefined
                                      ) {
                                        classStringNew =
                                          animateNotificationNew[indexToAnimate]
                                            .class;
                                        classStringNew =
                                          classStringNew + ' show';
                                        animateNotificationNew[
                                          indexToAnimate
                                        ].class = classStringNew;
                                      }
                                      this.setState({
                                        activeDrivers: animateNotificationNew,
                                      });
                                    }, 400);
                                  }
                                );
                              }, 400);
                            }
                          );

                          // internalNewDrivers.forEach(driver => {
                          //   if (
                          //     driver._id ===
                          //     driverWhoseStatusChanged[0].newDriver._id
                          //   ) {
                          //     console.log('driver', driver);
                          //     let classString;
                          //     classString = driver.class;
                          //     classString = classString + ' show';
                          //     driver.class = classString;
                          //   }
                          // });
                          // let sortedNewDrivers = sortBy(internalNewDrivers, [
                          //   'status',
                          // ]);
                          // this.setState(
                          //   {
                          //     activeDrivers: internalNewDrivers,
                          //   },
                          //   () => {
                          //     console.log(
                          //       'Third set state',
                          //       this.state.activeDrivers
                          //     );
                          //   }
                          // );
                        }
                        // /debugger;
                      }, 600);
                    }
                  );
                  //}, 1500);
                }
              );
            }, 600);
          }
        );
      }

      // Get the driver whose status has changed.
      let newDrivers = cloneDeep(this.props.activeDrivers);
      let oldDrivers = cloneDeep(prevProps.activeDrivers);
      setTimeout(() => {
        newDrivers.forEach((driver, index) => {
          if (
            newDrivers[index].status === 1 &&
            oldDrivers[index].status === 1
          ) {
            let currentDriver = cloneDeep(newDrivers[index]);
            let previousDriver = cloneDeep(oldDrivers[index]);
            if (currentDriver.orders.length === previousDriver.orders.length) {
              currentDriver.orders.forEach((order, orderIndex) => {
                if (
                  currentDriver.orders[orderIndex].orderStatus !==
                  previousDriver.orders[orderIndex].orderStatus
                ) {
                  let currentState = cloneDeep(this.state.activeDrivers);
                  currentState.forEach((stateDriver, index) => {
                    if (stateDriver._id === currentDriver._id) {
                      let newOrders = cloneDeep(currentDriver.orders);
                      stateDriver.orders = newOrders;
                    }
                  });
                  this.setState({
                    activeDrivers: currentState,
                  });
                }
              });
            }
          }
        });
      }, 1000);
    }
  }
  getIdleTime = timestamp => {
    if (timestamp) {
      let difference = getCurrentTimestamp() - timestamp;
      let minutes = parseInt(Number(difference) / 60000);
      let actualMins = minutes % 60;
      let hours = Math.floor(minutes / 60);
      if (hours !== 0) {
        return hours + ' hour ' + actualMins;
      } else {
        return actualMins;
      }
      //return minutes;
    } else return 0;
  };
  getOrderStatus = l => {
    if (l.orderStatus === '4') return 'destination';
    else if (
      l.orderStatus === '3' ||
      l.orderStatus === '13' ||
      l.orderStatus === '12'
    )
      return 'current-position';
    else return '';
  };
  updateIdleTime = () => {
    if (this.state.activeDrivers) {
      let activeDrivers = cloneDeep(this.state.activeDrivers);
      activeDrivers.forEach(activeDriver => {
        activeDriver.idleTime = this.getIdleTime(
          activeDriver.lastUpdatedTimestamp
        );
      });
      this.setState({ activeDrivers });
    }
  };

  getClass = p => {
    if (p.status === 0) return 'icon icon-shop';
    else if (p.status === 2) return 'icon icon-location-arrow';
    else return '';
  };
  render() {
    return (
      <div className='sidebar left'>
        {this.state.activeDrivers.map((p, idx) => (
          <div
            key={`${p.index}-${idx}`}
            className={`info-box driver ${p.class}`}
          >
            <div className='box-header'>
              <h2 className='box-name'>{p.statusName}</h2>
              <span className={this.getClass(p)} />
            </div>
            <div className='box-body'>
              <h3 className='driver-name'>{p.userName}</h3>
              <p className='time-status'>
                {p.status === 0 &&
                  `${
                    p.idleTime
                      ? p.idleTime === 1
                        ? `Waiting for ${p.idleTime} min`
                        : `Waiting for ${p.idleTime} mins`
                      : this.state.arrivedOrClocked[p._id]
                      ? this.state.arrivedOrClocked[p._id]
                      : `Clocked In`
                  }`}
                {p.status === 2 &&
                  `${
                    this.state.storeTimes[p._id]
                      ? this.state.storeTimes[p._id]
                      : `Arriving to Store`
                  }`}
              </p>
              {p.orders && (
                <ul className='address-list'>
                  {p.status === 1 &&
                    p.orders.map((l, index) => (
                      <li key={index} className={this.getOrderStatus(l)}>
                        <p>{l.address}</p>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

DriverStatusBoxes.propTypes = {
  activeDrivers: PropTypes.any,
};

export default DriverStatusBoxes;
