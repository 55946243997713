import { firestore, auth } from '../../firebase';
import ActionTypes from './constants';
import localStorage from 'local-storage';

export function getStoreSetup(storeId) {
  return dispatch => {
    firestore
      .collection('OrderSetup')
      .where('storeId', '==', storeId)
      .onSnapshot(querySnapshot => {
        var dataArray = [];
        querySnapshot.forEach(doc => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(orderSetupFetched(dataArray[0]));
      });
  };
}
export function getStore(storeId) {
  return dispatch => {
    firestore
      .collection('Stores')
      .doc(storeId)
      .onSnapshot(doc => {
        dispatch(storeFetched({ _id: doc.id, ...doc.data() }));
      });
  };
}

export function getAllShifts(storeId) {
  return dispatch => {
    firestore
      .collection('Shifts')
      .where('storeId', '==', storeId)
      .onSnapshot(querySnapshot => {
        var dataArray = [];
        querySnapshot.forEach(doc => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(allShiftsFetched(dataArray));
      });
  };
}

export function getActiveDrivers(storeId) {
  return dispatch => {
    let nextRef = firestore
      .collection('DriverLocations')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true);
    nextRef.onSnapshot(querySnapshot => {
      var dataArray = [];
      querySnapshot.forEach(doc => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchActiveDrivers(dataArray));
    });
  };
}
export function getActiveDriverRuns(storeId) {
  return dispatch => {
    let nextRef = firestore
      .collection('DriverRuns')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true);
    nextRef.onSnapshot(querySnapshot => {
      var dataArray = [];
      querySnapshot.forEach(doc => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchActiveDriverRuns(dataArray));
    });
  };
}
export function getActiveDriverShifts(storeId) {
  return dispatch => {
    let nextRef = firestore
      .collection('DriverShifts')
      .where('storeId', '==', storeId)
      .where('isShiftOver', '==', false)
      .orderBy('startAtTimestamp', 'desc');
    nextRef.onSnapshot(querySnapshot => {
      var dataArray = [];
      querySnapshot.forEach(doc => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchActiveDriverShifts(dataArray));
    });
  };
}

export function getActiveDriverRunOrders(storeId) {
  return dispatch => {
    let nextRef = firestore
      .collection('DriverRunOrders')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true);
    nextRef.onSnapshot(querySnapshot => {
      var dataArray = [];
      querySnapshot.forEach(doc => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchActiveDriverRunOrders(dataArray));
    });
  };
}
export function getNotifications(storeId) {
  return dispatch => {
    let nextRef = firestore
      .collection('DriverNotifications')
      .where('storeId', '==', storeId)
      .orderBy('dateTimestamp', 'desc');
    nextRef.onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          var dataArray = [];
          querySnapshot.forEach(doc => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(fetchNotifications(dataArray));
        }
      });
    });
  };
}
export function setListnerForAuthCode() {
  return dispatch => {
    if (localStorage.get('authDeviceId')) {
      let deviceId = localStorage.get('authDeviceId');
      let ref = firestore.collection('LoggedInDevices').doc(deviceId);
      ref.get().then(doc => {
        let obj = doc.data();
        if (obj) {
          if (!obj.isActive) {
            auth.signOut().then(() => {});
          }
        }
      });
      ref.onSnapshot(doc => {
        let obj = doc.data();
        if (obj) {
          if (!obj.isActive) {
            auth.signOut().then(() => {});
          }
        }
      });
    } else {
      // auth.signOut().then(() => {});
    }
  };
}

let guidGenerator = () => {
  var S4 = function() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4();
};

function getBrowserNameAndVersion() {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
    if (tem != null)
      return tem
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera')
        .replace('Edg ', 'Edge ');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
}

function getOSNameAndVersion() {
  // system
  var unknown = '-';
  var nAgt = navigator.userAgent;
  var nVer = navigator.appVersion;
  var os = unknown;
  var clientStrings = [
    { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    { s: 'Windows Vista', r: /Windows NT 6.0/ },
    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: 'Windows CE', r: /Windows CE/ },
    { s: 'Windows 3.11', r: /Win16/ },
    { s: 'Android', r: /Android/ },
    { s: 'Open BSD', r: /OpenBSD/ },
    { s: 'Sun OS', r: /SunOS/ },
    { s: 'Linux', r: /(Linux|X11)/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'Mac OS X', r: /Mac OS X/ },
    { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: 'QNX', r: /QNX/ },
    { s: 'UNIX', r: /UNIX/ },
    { s: 'BeOS', r: /BeOS/ },
    { s: 'OS/2', r: /OS\/2/ },
    {
      s: 'Search Bot',
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  for (var id in clientStrings) {
    var cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }

  var osVersion = unknown;

  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = 'Windows';
  }

  switch (os) {
    case 'Mac OS X':
      osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
      break;

    case 'Android':
      osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
      break;

    case 'iOS':
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
      osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
      break;
  }

  return { os, osVersion };
}

let getDeviceInfo = () => {
  let loginTime = new Date().getTime();
  console.log('ip address', window.publicIp);
  console.log('Browser Name and Version', getBrowserNameAndVersion());
  console.log('OS Name and Version', getOSNameAndVersion());
  console.log('Login Time', loginTime);
  let obj = {
    codeName: navigator.appCodeName,
    name: navigator.appName,
    version: navigator.appVersion,
    platform: navigator.platform,
    userAgent: navigator.userAgent,
    publicIp: window.publicIp,
    browserInfo: getBrowserNameAndVersion(),
    osInfo: getOSNameAndVersion(),
    loginTimestamp: loginTime,
  };
  return obj;
};

export function selfLogin(authCode) {
  return dispatch => {
    firestore
      .collection('StoreAuthCodes')
      .where('authCode', '==', authCode)
      .get()
      .then(querySnapshotMain => {
        var dataArrayMain = [];
        querySnapshotMain.forEach(doc => {
          dataArrayMain.push({ _id: doc.id, ...doc.data() });
        });
        if (dataArrayMain.length > 0) {
          firestore
            .collection('SystemUsers')
            .where('userRole', '==', '2')
            .where('type', '==', '1')
            .where('authCodeId', '==', dataArrayMain[0]._id)
            .get()
            .then(querySnapshot => {
              var dataArray = [];
              querySnapshot.forEach(doc => {
                dataArray.push({ _id: doc.id, ...doc.data() });
              });
              if (dataArray.length > 0) {
                auth
                  .signInWithEmailAndPassword(
                    dataArray[0].email,
                    dataArray[0].password
                  )
                  .then(() => {
                    // let obj = {
                    //   storeId: dataArray[0].storeId,
                    //   restaurantId: dataArray[0].restaurantId,
                    //   isActive: true,
                    //   deviceId: guidGenerator(),
                    //   deviceInfo: getDeviceInfo(),
                    // };
                    // firestore
                    //   .collection('LoggedInDevices')
                    //   .add(obj)
                    //   .then(doc => {
                    //     localStorage.set('authDeviceId', doc.id);
                    //   });
                  })
                  .catch(() => {
                    window.location = '/restaurant/login';
                  });
              } else {
                window.location = '/restaurant/login';
              }
            });
        } else {
          window.location = '/restaurant/login';
        }
      });
  };
}

export function deleteNotifications(notifications) {
  return dispatch => {
    var batch = firestore.batch();
    notifications.forEach(m => {
      let ref = firestore.collection('DriverNotifications').doc(m._id);
      batch.delete(ref);
    });
    batch.commit();
  };
}
function orderSetupFetched(orderSetup) {
  return {
    type: ActionTypes.OrderSetupSaved,
    orderSetup,
  };
}
function fetchActiveDrivers(drivers) {
  return {
    type: ActionTypes.FetchActiveDrivers,
    drivers,
  };
}
function fetchActiveDriverShifts(driverShifts) {
  return {
    type: ActionTypes.FetchActiveDriverShifts,
    driverShifts,
  };
}
function fetchActiveDriverRuns(driverRuns) {
  return {
    type: ActionTypes.FetchActiveDriverRuns,
    driverRuns,
  };
}
function fetchActiveDriverRunOrders(driverRunOrders) {
  return {
    type: ActionTypes.FetchActiveDriverRunOrders,
    driverRunOrders,
  };
}
function fetchNotifications(notifications) {
  return {
    type: ActionTypes.FetchNotifications,
    notifications,
  };
}

function allShiftsFetched(allShifts) {
  return {
    type: ActionTypes.FetchAllShifts,
    allShifts,
  };
}

function storeFetched(store) {
  return {
    type: ActionTypes.FetchStore,
    store,
  };
}
